<template>
  <div ref="logContainer" id="logContainer" />
</template>

<script>
export default {
  name: "Logs",
  mounted() {
    // logsend disabled until a newrelic version is available
    // this.$logSend.listen((level, page, message, clientInfo) => {
    //   if (!this.$refs["logContainer"]) return;
    //   const div = document.createElement("div");
    //   div.classList.add("log-event", level);
    //   div.innerText = `[${clientInfo.remoteIp}] - [${page}] - [${level}] - ${message}`;
    //   this.$refs["logContainer"].appendChild(div);
    // });
  },
};
</script>